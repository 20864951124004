import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicTableDialog = _resolveComponent("BasicTableDialog")!
  const _component_BasicTablePrintAndExport = _resolveComponent("BasicTablePrintAndExport")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BasicTableDialog, {
      title: "平台账户流水",
      subTitle: _ctx.platformAccountName,
      modelValue: _ctx.modalShow,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalShow) = $event)),
      searchInputs: _ctx.searchInputs,
      searchButtons: _ctx.searchButtons,
      tableColumns: _ctx.tableColumns,
      tableData: _ctx.tableData,
      pagination: _ctx.pagination,
      width: "1300px",
      top: "10vh"
    }, null, 8, ["subTitle", "modelValue", "searchInputs", "searchButtons", "tableColumns", "tableData", "pagination"]),
    _createVNode(_component_BasicTablePrintAndExport, {
      ref: "exportRef",
      tableColumns: _ctx.tableColumns,
      tableData: _ctx.tableData,
      fileName: "平台账户流水"
    }, null, 8, ["tableColumns", "tableData"])
  ]))
}