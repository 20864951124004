
import { BindFileInfo, bindUploadedFile, deleteBindUploadedFile, getFileBasePreviewUrl, uploadFileGetFileInfo } from "@/api/file";
import { addOrUpdateBankPlatformAccount, getPlatformSealImg, PlatformBankAccountAddOrUpdateData } from "@/api/pay";
import BasicFormDialog from "@/components/BasicPageLayout/BasicFormDialog.vue";
import BasicImageList from '@/components/BasicPageLayout/BasicImageList.vue'
import { Buttons, UploadFileExtend } from "@/types/BasicComponent";
import { FileType, SourceType } from "@/types/Common";
import { ElMessage } from "element-plus";
import { defineComponent, reactive, ref, toRef } from "vue";

type PartialFileInfoWithId = Partial<UploadFileExtend> & { id: number }

export default defineComponent({
  components: {
    BasicFormDialog,
    BasicImageList
  },
  emits: ['on-success'],
  setup(props, { emit }) {
    const modalShow = ref(false)

    const sealFileList = reactive<PartialFileInfoWithId[]>([])
    const bpaId = ref(0)
    // 1添加 2修改
    const actionType = ref(1)
    const show = async (initFormData?: PlatformBankAccountAddOrUpdateData) => {
      formData.value = {}

      actionType.value = initFormData ? 2 : 1
      modalShow.value = true

      if (initFormData) {
        bpaId.value = initFormData.id as number
        formData.value = initFormData
      }
     
      loadImage()
    }

    const loadImage = async () => {
      const baseUrlResp = await getFileBasePreviewUrl()
      const baseImgUrl = baseUrlResp.data.data

      const imgResp = await getPlatformSealImg(bpaId.value)
      const respFileList = imgResp.data.data as AnyArray

      sealFileList.length = 0
      respFileList.forEach((fileInfo, index) => {
        const url =  fileInfo.fileguid? baseImgUrl + fileInfo.fileguid: baseImgUrl.replace('userFileGuid', 'userFileId') + fileInfo.fileid
        sealFileList.push({ url, id: fileInfo.id, title: '盖章图片' })
      })
    }

    const onUpload = async (file: UploadFileExtend) => {

      const resp = await uploadFileGetFileInfo(file.raw)
      await bindUploadedFile({
        sourceId: bpaId.value,
        sourceTypeId: SourceType.BANK_PLATFORM_ACCOUNT,
        fileTypeId: FileType.SEAL_IMG,
        fileGuid: resp.userFileGuid,
        fileId: resp.userFileId,
      })
      loadImage()
    }

    const onFileDelete = (file: AnyObject) => {
      return new Promise<void>((resolve, reject) => {
        deleteBindUploadedFile([file.id])
        resolve()
      })
    }

    const footerButtons = reactive<Buttons>([
      {
        label: '保存',
        onClick: async () => {
          await addOrUpdateBankPlatformAccount(formData.value)
          ElMessage.success('修改成功')
          emit('on-success')
          modalShow.value = false
        }
      }, {
        label: '取消',
        type: 'default',
        onClick: () => modalShow.value = false
      }
    ])

    const formData = ref<PlatformBankAccountAddOrUpdateData>({})

    return {
      actionType,
      formData,
      modalShow,
      footerButtons,
      sealFileList,
      onUpload,
      onFileDelete,
      show
    }
  }
})
