
import { Buttons, Inputs, TableColumns } from "@/types/BasicComponent";
import { formatTime } from "@/utils/common";
import { defineComponent, reactive, ref, toRef } from "vue";
import BasicTableDialog from '@/components/BasicPageLayout/BasicTableDialog.vue'
import usePagination from "@/utils/composables/usePagination";
import BasicTablePrintAndExport from '@/components/BasicPageLayout/BasicTablePrintAndExport.vue'
import { PlatformAccountTraderFlowQueryParams, getPlatformAccountTraderFlow } from "@/api/pay";

export default defineComponent({
  components: {
    BasicTableDialog,
    BasicTablePrintAndExport
  },
  setup() {
    const modalShow = ref(false)
    const pagination = usePagination()

    const platformAccountName = ref('')
    const searchData = reactive<PlatformAccountTraderFlowQueryParams>({
      platformBankAccountNumber: ''
    })

    const show = (accountNo: string, accountName: string) => {
      modalShow.value = true
      platformAccountName.value = accountName
      searchData.platformBankAccountNumber = accountNo
      getTableData()
    }

    const getTableData = async () => {
      const respData = await getPlatformAccountTraderFlow(Object.assign({ pageNo: pagination.currentPage, pageSize: pagination.pageSize }, searchData))
      tableData.value = respData.data.data.records
      pagination.total = respData.data.data.total
      tableData.value.forEach((td, index) => td.index = pagination.pageOffset + index)
    }

    pagination.setCallback(getTableData)

    const exportRef = ref()

    const searchInputs = reactive<Inputs>([
      {
        type: 'select',
        label: '流水类型',
        model: toRef(searchData, 'traderType'),
        options: [
          { label: '收入', value: 1 },
          { label: '支出', value: 2 },
        ]
      },
      {
        type: 'group',
        divider: '-',
        label: '交易日期',
        inputs: [
          {
            type: 'date',
            placeholder: '开始日期',
            model: toRef(searchData, 'traderStartTime')
          }, {
            type: 'date',
            placeholder: '结束日期',
            model: toRef(searchData, 'traderEndTime')
          }
        ]
      }
    ])

    const searchButtons = reactive<Buttons>([
      {
        label: '查询',
        icon: 'el-icon-search',
        onClick: getTableData
      }, {
        label: '导出',
        icon: 'el-icon-download',
        onClick: () => exportRef.value.doExport()
      }
    ])

    const tableData = ref<AnyArray>([])
    const tableColumns = reactive<TableColumns>([
      {
        prop: 'index',
        label: '序号',
        width: '60px',
        align: 'center',
      }, {
        prop: 'requestCode',
        label: '流水号',
        minWidth: '200px',
        align: 'center',
        preventFormat: true
      }, {
        prop: 'btfTraderTime',
        label: '交易时间',
        minWidth: '150px',
        type: 'render',
        align: 'center',
        render: scope => formatTime(scope.row.btfTraderTime)
      }, {
        prop: 'btfAccountName',
        label: '交易户名',
        minWidth: '150px',
        align: 'center',
      }, {
        prop: 'btfAccountNumber',
        label: '交易卡号',
        minWidth: '150px',
        align: 'center',
      }, {
        prop: 'btfRcvamt',
        label: '收入金额',
        minWidth: '150px',
        align: 'center',
      }, {
        prop: 'btfPayamt',
        label: '支出金额',
        minWidth: '150px',
        align: 'center',
      }, {
        prop: 'btfDesc',
        label: '附言',
        minWidth: '200px',
        align: 'center',
      }
    ])

    return {
      show,
      exportRef,
      platformAccountName,
      pagination,
      modalShow,
      tableData,
      searchInputs,
      searchButtons,
      tableColumns,
    }
  }
})
