
import BasicTablePage from '@/components/BasicPageLayout/BasicTablePage.vue'
import { computed, defineComponent, onMounted, reactive, ref, toRef } from 'vue'
import usePagination from '@/utils/composables/usePagination'
import { Buttons, Inputs, TableColumns } from '@/types/BasicComponent'
import { formatTime, getTableColumnsSummary } from '@/utils/common'
import BasicTablePrintAndExport from '@/components/BasicPageLayout/BasicTablePrintAndExport.vue'
import { getPlatformBankAccountList, PlatformBankAccountListQueryParams } from '@/api/pay' 
import BankPlatfornAccountModifyDialog from '@/views/pay/BankPlatfornAccountModifyDialog.vue'
import BankPlatformAccnoutTraderFlowDialog from '@/views/pay/BankPlatformAccnoutTraderFlowDialog.vue'

export default defineComponent({
  components: {
    BasicTablePage,
    BasicTablePrintAndExport,
    BankPlatfornAccountModifyDialog,
    BankPlatformAccnoutTraderFlowDialog
  },
  setup() {
    const pagination = usePagination()
    const searchData = reactive<PlatformBankAccountListQueryParams>({})
    const tableData = ref<AnyArray>([])
    const badgeValues = reactive<AnyObject>({})

    const selectedTableData = ref<AnyArray>([])
    const selectCount = computed(() => selectedTableData.value.length)

    const getTableData = async () => {
      const res = await getPlatformBankAccountList(Object.assign({ pageNo: pagination.currentPage, pageSize: pagination.pageSize }, searchData))
      tableData.value = res.data.data.records
      pagination.total = res.data.data.total
    }
    pagination.setCallback(getTableData)

    onMounted(() => {
      getTableData()
    })

    const onSelectionChange = (selection: any[]) => selectedTableData.value = selection

    const exportRef = ref()
    const bankPlatfornAccountModifyDialogRef = ref()
    const bankPlatformAccnoutTraderFlowDialogRef = ref()

    const searchInputs = reactive<Inputs>([
      {
        label: '银行户名',
        type: 'input',
        placeholder: '请输入银行户名',
        clearable: true,
        model: toRef(searchData, 'bankAccountName')
      }, {
        label: '银行卡号',
        type: 'input',
        placeholder: '请输入银行卡号',
        clearable: true,
        model: toRef(searchData, 'bankAccountNumber')
      }
    ])

    const searchButtons = reactive<Buttons>([
      {
        label: '查询',
        icon: 'el-icon-search',
        onClick: getTableData
      }, {
        label: '添加',
        icon: 'el-icon-plus',
        onClick: () => {
          bankPlatfornAccountModifyDialogRef.value.show()
        }
      }, {
        label: '导出',
        icon: 'el-icon-download',
        onClick: () => {
          exportRef.value.doExport()
        }
      }
    ])

    const tableColumns = reactive<TableColumns>([
      {
        prop: 'index',
        type: 'index',
        label: '序号',
        width: '70px',
        align: 'center',
        index: idx => pagination.pageOffset + idx
      }, {
        prop: 'bankAccountName',
        label: '银行户名',
        minWidth: '200px',
      }, {
        prop: 'bankAccountNumber',
        label: '银行卡号',
        minWidth: '200px',
        preventFormat: true
      }, {
        prop: 'bankName',
        label: '银行名称',
        minWidth: '150px',
      }, {
        prop: 'bankCode',
        label: '银行代码',
        minWidth: '100px'
      }, {
        prop: 'bankCurrentBalance',
        label: '当前余额',
        minWidth: '200px',
      }, {
        prop: 'bankDescription',
        label: '描述',
        minWidth: '200px',
      }, {
        prop: 'bankCreate',
        label: '创建时间',
        minWidth: '180px',
        type: 'render',
        render: scope => formatTime(scope.row.bankCreate)
      }, {
        prop: 'bankModified',
        label: '修改时间',
        minWidth: '180px',
        type: 'render',
        render: scope => formatTime(scope.row.bankModified)
      }, {
        prop: 'bankIsValid',
        label: '是否有效',
        minWidth: '80px',
        type: 'status',
        align: 'center',
        statusType: [
          { label: '有效', type: 'success', value: 1 },
          { label: '无效', type: 'info', value: 0 }
        ]
      }, {
        type: 'button',
        label: '操作',
        fixed: 'right',
        buttons: [
          {
            label: '编辑',
            onClick: scope => bankPlatfornAccountModifyDialogRef.value.show(scope.row)
          }, {
            label: '流水',
            onClick: scope => bankPlatformAccnoutTraderFlowDialogRef.value.show(scope.row.bankAccountNumber, scope.row.bankAccountName)
          }
        ]
      }
    ])
    
    const mySummaryMethod = (scope: AnyObject) => {
      return getTableColumnsSummary(['bankCurrentBalance'], scope.columns, scope.data)
    }
    
    return {
      getTableData,
      onSelectionChange,
      searchInputs,
      searchButtons,
      tableColumns,
      pagination,
      tableData,
      selectCount,
      exportRef,
      bankPlatfornAccountModifyDialogRef,
      bankPlatformAccnoutTraderFlowDialogRef,
      mySummaryMethod
    }
  }
})
